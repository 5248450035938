/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./slack.vue?vue&type=template&id=d4783a24"
import script from "./slack.vue?vue&type=script&lang=js"
export * from "./slack.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Loading: require('/code/src/components/Loading.vue').default,IconButton: require('/code/src/components/button/IconButton.vue').default,AlertArea: require('/code/src/components/AlertArea.vue').default,Input: require('/code/src/components/input/Input.vue').default,SectionSubTitle: require('/code/src/components/text/SectionSubTitle.vue').default,FloatingTooltip: require('/code/src/components/tooltip/FloatingTooltip.vue').default,ContentArea: require('/code/src/components/ContentArea.vue').default,TextButton: require('/code/src/components/button/TextButton.vue').default})
